











import akkordeonItem from './akkordeonItem.vue';
export default {
  props: {
    groupdata: Object,
    activated: String,
    siteLang:String
  },
  components: {
    akkordeonItem,
  },
};
