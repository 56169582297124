


























































import { BASEURL } from '@libTs/cms.globals';
export default {
  props: {
    itemdata: Object,
    siteLanguage:String
  },
  methods: {},
  data() {
    return {
      enabled: false,
      baseurl: BASEURL,
    };
  },
};
