var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "h-full",
      on: {
        mouseenter: function($event) {
          return _vm.show()
        },
        mouseleave: function($event) {
          return _vm.hide()
        },
        click: function($event) {
          return _vm.play()
        }
      }
    },
    [
      _c("div", { staticClass: "relative h-full w-full" }, [
        _c(
          "div",
          { staticClass: "absolute z-10 center", attrs: { id: "play" } },
          [
            _c("button", {}, [
              _c("img", {
                staticClass: "h-20 w-20",
                attrs: {
                  src: _vm.baseurl + "assets/" + _vm.icon + ".svg",
                  alt: _vm.icon
                }
              })
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "video",
          {
            staticClass: "object-cover w-full h-full opacity-40",
            attrs: { id: "video", muted: "", loop: "true" },
            domProps: { muted: true },
            on: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.play()
              }
            }
          },
          [_c("source", { attrs: { src: _vm.movie } })]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }