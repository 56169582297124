




































import akkordeonGroup from './components/akkordeonGroup.vue';
export default {
  name: 'akkordeonMarke',
  props: {
    data: Array,
    siteLanguage:String
  },
  methods: {
    active(e) {
      const elements = document.querySelectorAll('#tabs');
      for (const key in elements) {
        if (Object.prototype.hasOwnProperty.call(elements, key)) {
          const element = elements[key];
          if (element.innerText == this.activeTab) {
            element.classList.add('active');
          } else {
            element.classList.remove('active');
          }
        }
      }
    },
  },
  computed: {},
  data() {
    return {
      activeTab: String,
    };
  },
  mounted() {
    document.getElementById('tabs').classList.add('active');
    document.getElementById('tabs').click();
  },
  components: {
    akkordeonGroup,
  },
};
