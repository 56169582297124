var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "relative flex flex-col w-full h-full" }, [
    !_vm.enabled
      ? _c("div", { staticClass: "absolute z-10 ml-2/24 mt-4" }, [
          _c("a", { staticClass: "block", attrs: { href: "/" } }, [
            _c("img", {
              staticClass: "w-auto h-16",
              attrs: { src: _vm.logo, alt: "Meyers Sporthaus" }
            })
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "ml-auto grid grid-cols-2 h-full " }, [
      _c("div", { staticClass: "mobileNavButtonsFlex" }, [
        _c(
          "a",
          {
            staticClass: "w-full h-full relative",
            attrs: { href: "tel:" + _vm.contact }
          },
          [
            _c("img", {
              staticClass: "h-4 center",
              attrs: {
                src: _vm.baseurl + "assets/Icon-PhoneMobile.svg",
                alt: "anrufen"
              }
            })
          ]
        )
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          on: {
            click: function($event) {
              _vm.enabled = !_vm.enabled
            }
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "relative h-16 w-16 bg-summer1",
              class: { enabled: "fixed top-2 right-2" }
            },
            [
              _c("div", { staticClass: "z-30 center" }, [
                !_vm.enabled
                  ? _c("img", {
                      staticClass: "h-6",
                      attrs: {
                        src: _vm.baseurl + "assets/Icon-MobileMenu.svg",
                        alt: "Menu"
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.enabled
                  ? _c("i", {
                      staticClass:
                        "fa-sharp fa-solid fa-xmark text-white text-3xl "
                    })
                  : _vm._e()
              ])
            ]
          )
        ]
      )
    ]),
    _vm._v(" "),
    _vm.enabled
      ? _c(
          "div",
          {
            staticClass: "fixed z-20 bg-summer1 w-screen h-screen flex flex-col"
          },
          [
            _c(
              "div",
              {
                staticClass: "grid grid-flow-row mx-2/24 mt-28 gap-5 max-h-fit"
              },
              _vm._l(_vm.data, function(item, index) {
                return _c("div", { key: index }, [
                  _c(
                    "a",
                    { staticClass: "no-underline", attrs: { href: item.url } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "franklinDemi-f2",
                          class: { "text-white": _vm.entry == item.url }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(item.text.toUpperCase()) +
                              "\n          "
                          )
                        ]
                      )
                    ]
                  )
                ])
              }),
              0
            ),
            _vm._v(" "),
            _c("div", { staticClass: "mt-auto mx-2/24 mb-12" }, [
              _c(
                "div",
                { staticClass: "flex flex-row gap-6" },
                [
                  _vm._l(_vm.impressum, function(item, index) {
                    return _c("div", { key: index }, [
                      _c(
                        "a",
                        { staticClass: "text-dark", attrs: { href: item.url } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(item.text) +
                              "\n          "
                          )
                        ]
                      )
                    ])
                  }),
                  _vm._v(" "),
                  _c("nav", { staticClass: "w-full" }, [
                    _c(
                      "div",
                      { staticClass: "flex justify-end ml-auto mr-0 gap-4" },
                      _vm._l(_vm.switcherLinks, function(item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "nav-link franklinDemi-f5 list-none",
                            class: item.current ? "text-winter2 underline" : ""
                          },
                          [
                            _c(
                              "a",
                              {
                                attrs: {
                                  hreflang: item.language,
                                  lang: item.language,
                                  href: item.url
                                }
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(item.title.toUpperCase()))
                                ]),
                                _vm._v(" "),
                                _c("span", {
                                  class:
                                    "flag-icon flag-icon-" + item.countryCode
                                })
                              ]
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ])
                ],
                2
              )
            ])
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }