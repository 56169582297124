var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("div", [
        _c(
          "button",
          {
            staticClass: "akkordeon-btn franklinDemi-f4",
            on: {
              click: function($event) {
                _vm.enabled = !_vm.enabled
              }
            }
          },
          [
            _c("div", [_vm._v(_vm._s(_vm.itemdata.heading))]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "ml-auto relative inline-block" },
              [
                _c(
                  "transition",
                  { attrs: { name: "fadein", mode: "out-in" } },
                  [
                    !_vm.enabled
                      ? _c("img", {
                          staticClass: "h-4",
                          attrs: {
                            src: _vm.baseurl + "assets/Icon-Arrow-Down.svg",
                            alt: "Arrow Down"
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.enabled
                      ? _c("img", {
                          staticClass: "h-4",
                          attrs: {
                            src: _vm.baseurl + "assets/Icon-Arrow-Up.svg",
                            alt: "Arrow Up"
                          }
                        })
                      : _vm._e()
                  ]
                )
              ],
              1
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c("Transition", { attrs: { name: "fade" } }, [
        _vm.enabled
          ? _c("div", [
              _c("div", {
                staticClass: "px-6",
                domProps: { innerHTML: _vm._s(_vm.itemdata.text) }
              }),
              _vm._v(" "),
              _vm.itemdata.marke.length > 0
                ? _c("hr", { staticClass: "my-7 border-summer1 mx-6" })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "akkordeon-grid" },
                _vm._l(_vm.itemdata.marke, function(item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass:
                        "flex justify-center items-center w-full h-fit",
                      attrs: { id: item.heading }
                    },
                    [
                      _c("div", {
                        staticClass: "brandImg w-[60%] brand-size",
                        style: { backgroundImage: "url(" + item.picture + ")" }
                      })
                    ]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c("div", { staticClass: "pb-4" }, [
                _vm.itemdata.preisliste !== null
                  ? _c(
                      "a",
                      {
                        staticClass: "ml-6 flex flex-row linkDark",
                        attrs: { href: _vm.itemdata.preisliste, download: "" }
                      },
                      [
                        _c("i", {
                          staticClass:
                            "fa-solid fa-arrow-down-to-line text-xl mr-4"
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "text-center" }, [
                          _vm._v(
                            _vm._s(
                              _vm.siteLanguage == "en"
                                ? "Download price list"
                                : "Preisliste herunterladen"
                            )
                          )
                        ])
                      ]
                    )
                  : _vm._e()
              ])
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }