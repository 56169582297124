






































































import { BASEURL } from '@libTs/cms.globals';
import baseButton from '../baseButton.vue';
export default {
  components: { baseButton },
  props: {
    data: Array,
    impressum: Array,
    logo: String,
    contact: String,
    entry: String,
    switcherLinks:Array
  },
  data() {
    return {
      baseurl: BASEURL,
      enabled: false,
    };
  },
};
