var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "border-b border-summer1" },
      [
        _c(
          "transition-group",
          { attrs: { name: "wide", mode: "out-in" } },
          _vm._l(_vm.data, function(block, index) {
            return _c(
              "button",
              {
                key: "tab-" + index,
                staticClass: "p-1 mr-14 franklinDemi-f4",
                attrs: { id: "tabs", name: block.heading },
                on: {
                  click: function($event) {
                    _vm.activeTab = $event.target.innerText
                    _vm.active($event.target)
                  }
                }
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(block.heading.toUpperCase()) +
                    "\n      "
                )
              ]
            )
          }),
          0
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "mt-10" },
      [
        _c(
          "transition-group",
          { attrs: { name: "fadein" } },
          _vm._l(_vm.data, function(block, index) {
            return _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeTab == block.heading.toUpperCase(),
                    expression: "activeTab == block.heading.toUpperCase()"
                  }
                ],
                key: index + "-" + block.heading,
                attrs: { name: "akkordeonItems", id: block.heading }
              },
              [
                _c(
                  "keep-alive",
                  [
                    _c("akkordeonGroup", {
                      attrs: { groupdata: block, siteLang: _vm.siteLanguage }
                    })
                  ],
                  1
                )
              ],
              1
            )
          }),
          0
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }