<template>
  <div class="h-full" @mouseenter="show()" @mouseleave="hide()" @click="play()">
    <div class="relative h-full w-full">
      <div class="absolute z-10 center" id="play">
        <button  class="">
          <img class="h-20 w-20" :src="baseurl + 'assets/' + icon + '.svg'"  :alt="icon"/>
        </button>
      </div>
      <video
        class="object-cover w-full h-full opacity-40"
        id="video"
        muted
        loop="true"
        @keyup.enter="play()"
      >
        <source :src="movie" />
      </video>
    </div>
  </div>
</template>
<script>
import { BASEURL } from '@libTs/cms.globals';
export default {
  props: {
    movie: String,
  },
  data() {
    return {
      icon: 'Icon-CirclePlay-white',
      baseurl: BASEURL,
    };
  },
  methods: {
    play() {
      const video = document.getElementById('video');
      video.classList.toggle('play');
      if (video.classList.contains('play')) {
        video.play();
        video.classList.toggle('opacity-40');
      } else {
        video.pause();
        video.classList.toggle('opacity-40');
      }
      this.icon == 'Icon-CirclePlay-white'
        ? (this.icon = 'Icon-CirclePause-white')
        : (this.icon = 'Icon-CirclePlay-white');
      video.focus();
      if (video.classList.contains('play')) {
        const playButton = document.getElementById('play');
        playButton.style.display == ''
          ? (playButton.style.display = 'none')
          : (playButton.style.display = '');
      }
    },
    show() {
      const playButton = document.getElementById('play');
      playButton.style.display = '';
    },
    hide() {
      if (document.getElementById('video').classList.contains('play')) {
        const playButton = document.getElementById('play');
        playButton.style.display = 'none';
      }
    },
  },
};
</script>
<style lang=""></style>
