










import { BASEURL } from '@libTs/cms.globals';
export default {
  props: {
    text: String,
    location: String,
  },
  data() {
    return {
      baseurl: BASEURL,
    };
  },
};
