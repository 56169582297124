var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    _vm._l(_vm.groupdata.akkordeon, function(block, index) {
      return _c(
        "div",
        { key: index, staticClass: "bg-footer my-2" },
        [
          _c("akkordeon-item", {
            attrs: { itemdata: block, siteLanguage: _vm.siteLang }
          })
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }