var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "a",
      {
        staticClass:
          "btn-wide-green-blue btn-wide-blue-white-hover franklinDemi-f4",
        attrs: { href: _vm.baseurl + _vm.location }
      },
      [_vm._v("\n    " + _vm._s(_vm.text) + "\n  ")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }