var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "grid-teammember" },
      _vm._l(_vm.data, function(item, index) {
        return _c("div", { key: index, class: { hidden: _vm.count(index) } }, [
          _c("div", {
            staticClass: "bg-img aspect-video s:pt-16/9 flex flex-row",
            style: { backgroundImage: " url(" + item.image + ")" }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "mt-5" }, [
            _c("div", { staticClass: "flex flex-col mt-auto" }, [
              _c(
                "div",
                {
                  staticClass: "franklinDemi-f3",
                  attrs: { id: "role" + index }
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(item.firstname.toUpperCase()) +
                      "\n            " +
                      _vm._s(item.lastname.toUpperCase()) +
                      "\n          "
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", {}, [
              _c(
                "div",
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(item.role) +
                      " " +
                      _vm._s(_vm.siteLanguage == "en" ? "in" : "bei") +
                      "\n            "
                  ),
                  _vm._l(item.filiale, function(filiale, index) {
                    return _c("span", { key: index }, [
                      _vm._v(_vm._s(filiale.title)),
                      index != item.filiale.length - 1
                        ? _c("span", [_vm._v(", ")])
                        : _vm._e()
                    ])
                  })
                ],
                2
              )
            ])
          ])
        ])
      }),
      0
    ),
    _vm._v(" "),
    _vm.data.length >= 9
      ? _c("div", { staticClass: "flex flex-row justify-center mt-7" }, [
          _vm.isTeam == "false"
            ? _c(
                "a",
                {
                  staticClass:
                    "btn-wide-green-blue btn-wide-blue-white-hover no-underline franklinDemi-f4",
                  attrs: { href: _vm.team }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.siteLanguage == "en" ? "Show more" : "Mehr Anzeigen"
                    )
                  )
                ]
              )
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }