








































import { BASEURL } from '@libTs/cms.globals';
import baseButton from '../baseButton.vue';
export default {
  components: { baseButton },
  props: {
    data: Array,
    isTeam: String,
    team: String,
    siteLanguage:String
  },
  data() {
    return {
      divs: Object,
      baseurl: BASEURL,
      enabled: false,
    };
  },
  methods: {
    count(key: Number) {
      if (
        window.matchMedia('(max-width:768px)').matches &&
        key >= 3 &&
        this.isTeam == 'false'
      ) {
        return true;
      } else if (key >= 9 && this.isTeam == 'false') {
        return true;
      } else {
        return false;
      }
    },
    resizeWindow() {
      this.$forceUpdate();
    },
  },
  computed: {},
  created() {
    window.addEventListener('resize', this.resizeWindow);
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeWindow);
  },
};
